export default [
  {
    title: "Add New Item",
    icon: "FilePlusIcon",
    route: "sell_refurbish",
    user_types: [1]
  },
  {
    title: "Dashboard",
    route: "analytics",
    icon: "ActivityIcon",
    module_id: "analytics",
    user_types: [1]
  },
  {
    title: "Items ",
    route: "product_list",
    icon: "PackageIcon",
    module_id: "products",
    user_types: [1]
  },
  {
    title: "Notifications",
    route: "notifications",
    icon: "MessageSquareIcon",
    module_id: "notifications",
    user_types: [1]
  },
  {
    title: "FAQ's",
    route: "help-support",
    icon: "HelpCircleIcon",
    module_id: "help-support",
    user_types: [1]
  },
  {
    title: "Seller Guide",
    route: "seller-guide",
    icon: "BookOpenIcon",
    module_id: "seller-guide",
    user_types: [1]
  }, 
  {
    title: "Account Settings",
    route: "account-setting",
    icon: "SettingsIcon",
    module_id: "account-setting",
    user_types: [1]
  },
  {
    title: "Back to Revivify.com",
    under_line: true,
    href: "https://www.revivify.com/",
    user_types: [1],
    target: "_self"
  },
  // ADMIN MENUS //
  {
    title: "Add New Item",
    icon: "FilePlusIcon",
    route: "sell_refurbish",
    user_types: [2,3,4,5]
  },
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "ActivityIcon",
    module_id: "admin-dashboard",
    user_types: [2,3]
  },
  {
    title: "Masters",
    icon: "PackageIcon",
    module_id: "admin-masters",
    user_types: [2],
    children: [
      {
        title: "Comission Tagging",
        route: { name: "ComissionTagging-list", params: { tag_type_name: "ComissionTagging" } }
      },
      {
        title: "Services",
        route: { name: "service-list" }
      },
      {
        title: "Item Type",
        route: { name: "ItemType-list", params: { tag_type_name: "ItemType" } }
      },
      {
        title: "Designer",
        route: { name: "Designer-list", params: { tag_type_name: "Designer" } }
      },
      {
        title: "Condition",
        route: { name: "Condition-list", params: { tag_type_name: "Condition" } }
      },
      {
        title: "Material",
        route: { name: "Material-list", params: { tag_type_name: "Material" } }
      },
      {
        title: "Item Trend",
        route: { name: "Trend-list", params: { tag_type_name: "Trend" } }
      },
      {
        title: "Item Rarity",
        route: { name: "Rarity-list", params: { tag_type_name: "Rarity" } }
      },
      {
        title: "Indian Bottom Waist",
        route: { name: "IndianBottomWaist-list", params: { tag_type_name: "IndianBottomWaist" } }
      },
      {
        title: "Indian Bust",
        route: { name: "IndianBust-list", params: { tag_type_name: "IndianBust" } }
      },
      {
        title: "Indian Top Hip",
        route: { name: "IndianTopHip-list", params: { tag_type_name: "IndianTopHip" } }
      },
      {
        title: "Western Bottom",
        route: { name: "WesternBottom-list", params: { tag_type_name: "WesternBottom" } }
      },
      {
        title: "Western Top",
        route: { name: "WesternTop-list", params: { tag_type_name: "WesternTop" } }
      },
      {
        title: "Currency",
        route: { name: "currency-list", }
      },
    ]
  },
  {
    title: "Items",
    icon: "BoxIcon",
    route: "admin-items",
    module_id: "admin-items",
    user_types: [2,3,4,5]
  },
  {
    title: "Users Management",
    icon: "UsersIcon",
    route: "admin-user-management",
    module_id: "admin-user-management",
    user_types: [2,3,4]
  },
  {
    title: "Finance",
    icon: "DollarSignIcon",
    route: "admin-finance-management",
    module_id: "admin-finance-management",
    user_types: [2]
  },
  {
    title: "Account Settings",
    icon: "SettingsIcon",
    route: "admin-account-settings",
    module_id: "admin-account-settings",
    user_types: [2,3,4,5]
  },
  {
    title: "Account Notifications",
    icon: "MessageSquareIcon",
    route: "admin-notifications",
    module_id: "admin-notifications",
    user_types: [2,3]
  },
  {
    title: "Item Logs",
    icon: "FileTextIcon",
    route: "admin-item-logs",
    module_id: "admin-item-logs",
    user_types: [2,3]
  },
  {
    title: "Payout",
    icon: "CreditCardIcon",
    route: "admin-payout",
    module_id: "admin-payout",
    user_types: [2],
    user_emails: ['shuaib.khan@enpointe.io', 'ajay.chauhan@enpointe.io', 'Akash@revivify.com', 'akash@aashniandco.com']
  },
  {
    title: "WordPress Dasboard",
    icon: "PackageIcon",
    module_id: "admin-masters",
    user_types: [2],
    user_emails: ['ajay.chauhan@enpointe.io', 'shuaib.khan@enpointe.io'],
    children: [
      {
        title: "Product",
        icon: "PackageIcon",
        module_id: "admin-wp-product",
        user_types: [2],
        children: [
          {
            title: "All Product",
            route: "wp-all-product"
          },
          {
            title: "Add Product",
            route: "wp-add-product"
          }
        ]
      },
      /* {
        title: "Comission Tagging",
        route: { name: "ComissionTagging-list", params: { tag_type_name: "ComissionTagging" } }
      } */
    ]
  },
];