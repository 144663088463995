<template>
  <ul>
    <component
      v-for="item in items"
      :is="resolveNavItemComponent(item)"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
//import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";
import tabs from "@/config/tabs";
import store from "@/store";

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      //navItems: tabs,
    };
  },
  computed: {},
  methods: {
    resolveNavItemComponent(item) {
      // console.log(item.title, " ", item);
      let user_data = store.getters["user/getUserDetails"];
      if(item.user_emails && item.user_emails.length > 0){
        if (item.user_types.includes(user_data.user_role_id) && item.user_emails.includes(user_data.user_email)) {
          if (item.header) return "vertical-nav-menu-header";
          if (item.children) return "vertical-nav-menu-group";
          return "vertical-nav-menu-link";
        } else {
          return null;
        }
      }else{
        if (item.user_types.includes(user_data.user_role_id)) {
          if (item.header) return "vertical-nav-menu-header";
          if (item.children) return "vertical-nav-menu-group";
          return "vertical-nav-menu-link";
        } else {
          return null;
        }
      }
      
    },
  },
  setup() {
    provide("openGroups", ref([]));

    return {
      //resolveNavItemComponent,
    };
  },
};
</script>
