<template>
  <div>
    <hr />
    <p class="clearfix mb-0">
      <!-- <span class="float-md-left d-block d-md-inline-block mt-25">
        COPYRIGHT © {{ new Date().getFullYear() }}
        <b-link class="ml-25" href="https://binarynumbers.io" target="_blank"
          >REVIVIFY</b-link
        >
        <span class="d-none d-sm-inline-block">, All rights Reserved</span>
      </span> -->
    </p>

    <b-row class="mt-2">
      <b-col md="2" v-for="(fitem, index) in footer_menu" :key="index + 'foot'">
        <h4 class="text-black mb-1">
          <b>{{ fitem.title }}</b>
        </h4>
        <ul
          style="list-style-type: none; padding: 0px"
          v-for="item in fitem.data"
          :key="item.name"
        >
          <li class="wp-footer-menu-li">
            <a class="footer-menu-link" :href="item.link">{{ item.name }}</a>
          </li>
        </ul>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BLink, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BRow,
    BCol,
  },

  data() {
    return {
      footer_menu: [
         
        {
          title: "COMPANY",
          data: [
            {
              name: "About Us",
              link: "/about-us",
            },
            {
              name: "Sustainability",
              link: "https://www.revivify.com/sustainability/",
            },
            {
              name: "Seller Terms and Conditions",
              link: "https://www.revivify.com/terms-and-conditions-seller/",
            },
            {
              name: "Privacy Policy",
              link: "https://www.revivify.com/privacy-policy/",
            },
          ],
        },
        {
          title: "CUSTOMER CARE",
          data: [
            {
              name: "FAQ's",
              link: "https://revivify.com/faqs/",
            },
            {
              name: "Brands We Accept",
              link: "https://www.revivify.com/designers/",
            },
            {
              name: "Contact Us",
              link: "https://www.revivify.com/contact-us-revivify/",
            },
          ],
        },
        {
          title: "QUICK LINKS",
          data: [
            {
              name: "How to Sell",
              link: "https://www.revivify.com/sell/onboarding#seller_tab",
            },
            // {
            //   name: "New In",
            //   link: "https://www.revivify.com/product-category/new-in/",
            // },
            // {
            //   name: "Bridal Lehengas",
            //   link: "https://www.revivify.com/product-category/lehenga/",
            // },
          ],
        },
        {
          title: "SOCIAL",
          data: [
            {
              name: "Facebook",
              link: "https://www.facebook.com/revivifyhq/",
            },
            {
              name: "Twitter",
              link: "https://twitter.com/revivify_hq",
            },
            {
              name: "Instagram",
              link: "https://www.instagram.com/revivifyhq/",
            },
            {
              name: "LinkedIn",
              link: "https://www.linkedin.com/company/revivifyhq/",
            },
          ],
        },
        {
          title: "Copyright",
          data: [
            {
              name: "Revivify - Copyright © 2022 - All Rights Reserved"
            }
          ],
        },
      ],
    };
  },
};
</script>


<style>
.wp-footer-menu-li > a {
  color: #a5a1a1 !important;
}
</style>